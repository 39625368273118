@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css');

:root
{
    /* COLORES */
    --white: #ffffff;
    --blue: #255BEF;
    --opacity-blue: #B0D5FF;
    --dark: #121212;
    --green: #05FE00;
    --opacity-green: #CAFFE6;
    --yellow: #FFE500;
    --opacity-yellow: #FFFACF;
    --red: #B71C1C;
    --opacity-red: #FFCDD2;
    --purple: #311B92;
    --opacity-purple: #D1C4E9;
    --gray: #263238;
    --opacity-gray: #ECEFF1;
    --fucsia: #880E4F;
    --opacity-fucsia: #FCE4EC;


    /* TAMAÑOS DE FUENTES */
    --fs-1: clamp(3rem, 1.875rem + 5vw, 6.25rem) !important;              /* max 100px */
    --fs-2: clamp(1.875rem, 1.5505rem + 1.4423vw, 2.8125rem)!important;   /* max 450px */
    --fs-3: clamp(1.5625rem, 1.3462rem + 0.9615vw, 2.1875rem) !important; /* max 35px  */

    /* TRANSICIONES */
    --transitions: all .3s ease;
    --transitions-animate: all 1.5s ease;

    /* RADIUS */
    --radius-8: 8px;
    --radius-16: 16px;
    --radius-50: 50px;

    /* GRADIENTE */
    --gradient-1: linear-gradient(119deg, rgba(41, 98, 255, 1) 0%, rgba(18, 18, 18, 1) 50%);
    --gradient-2: radial-gradient(circle, rgba(41,98,255,0.7175245098039216) 0%, rgba(18,18,18,1) 62%);
}

html
{
    scroll-behavior: smooth;
}

body
{
    background: var(--dark);
    width: 100%;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    color: var(--white);
    min-width: 320px;
    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 768px)
{
    body 
    {
        height: 5rem; 
    }  
}

body.no-scroll
{
    overflow-y: hidden;
}

span, p, h1, h2, h3, h4, h5 
{
    margin: 0;
    padding: 0;
    line-height: 1.25;
}

ul, li 
{
    list-style: none;
    margin: 0;
    padding: 0;
}

a 
{
    text-decoration: none;
}

*
{
    box-sizing: border-box;
}

.space-content 
{
    padding: 3rem 0;
}

@media screen and (min-width: 768px)
{
    .space-content 
    {
        padding: 6.25rem 0;
    }
}


@keyframes arrowDown 
{
    0% { transform: translateY(0%) }
    50% { transform: translateY(20%) }
    100% { transform: translateY(0%) }
}

@keyframes scale-up 
{
    0% { scale: 1; }
    50% { scale: 1.1; }
    100% { scale: 1; }
}

@keyframes fade-away
{
    to { opacity: 0; }
}

@keyframes fadeInBack
{
    0% { opacity: .75; }
    50% { opacity: 1; }
    100% { opacity: .75; }
}

.title-section
{
    font-size: var(--fs-2);
    text-align: center;
    font-weight: bold;
    margin-bottom: 4rem;
    color: var(--white);
}

.fill {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }

.btn 
{
    width: 10rem;
    height: 3rem;
    box-shadow: none;
    background: transparent;
    border-radius: var(--radius-8);
    font-size: 1rem;
    transition: var(--transitions);
    text-transform: none;
    border: 1px solid rgba(255,255,255,.2);
}

.btn:hover 
{
    transform: scale(1.1);
    transition: var(--transitions);
}

.btn.borde 
{
    border: 1px solid var(--white);
    color: var(--white);
}

.btn.bg 
{
    background: var(--fucsia);
    color: var(--dark-blue);
} 

.loader
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader .spinner-border
{
    width: 4rem;
    height: 4rem;
}

/*************************************************************************/
/* HEADER ****************************************************************/
/*************************************************************************/

header 
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.75rem;
    z-index: 9999;
    transition: var(--transitions);
}

@media screen and (min-width: 768px)
{
    header 
    {
        height: 5rem; 
    }  
}

header.act 
{
    background: rgba(0,0,0,.20);
    backdrop-filter: blur(20px);
    transition: var(--transitions);
}

header .header-int
{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .redes 
{
  display: flex;
}

header .redes .redes-item i 
{
    font-size: 2.188rem;
    color: var(--white);
    padding: 0 .3rem;
    transition: var(--transitions);
}

header .redes .redes-item i:hover 
{
    transform: scale(1.2);
    transition: var(--transitions);
}

header .menu .icon-menu
{
    font-size: 2.5rem;
    cursor: pointer;
}

@media screen and (max-width: 768px)
{
    header nav 
    {
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        top: 0rem;
        background: var(--dark-blue);
        opacity: 0;
        transition: var(--transitions);
        animation-delay: 1s;
        z-index: -1;
    }
    
    header nav.act 
    {
        height: auto;
        opacity: 1; 
        padding: 5rem 0;
        transition: var(--transitions);
        animation-delay: 1s;
        background: var(--dark);
        border-bottom: 1px solid var(--white);
    }

    header nav.act:before 
    {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
}

header nav ul
{
    width: 100%;
}

@media screen and (min-width: 768px)
{
    header nav ul
    {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    } 
}

@media screen and (min-width: 768px)
{
    header nav ul
    {
        justify-content: end;
    }   
}

header nav ul li .enlaceSeccion
{
    color: var(--white);
    font-weight: 500;
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: var(--radius-8);
    transition: var(--transitions);
}

header nav ul li:hover a
{
    color: var(--blue);
    transition: var(--transitions);
}


.ctn-idioma
{
  position: relative;
}

.ctn-idioma .dropdown-toggle
{
    background: none;
    border: none;
    color: var(--white);
}

.ctn-idioma .dropdown-toggle::after{
  vertical-align: .1rem;
  margin-left: .5rem;
}

.ctn-idioma .dropdownMenu
{
    position: absolute;
    display: none;
    background-color: var(--white);
    border-radius: var(--radius-8);
    width: 90px;
    height: 90px;
    overflow: hidden;
    transform: translate(-5px, 10px);
}

.ctn-idioma .dropdownMenu.act 
{
  visibility: visible;
  display: block;
}

.ctn-idioma .dropdownMenu li
{
    width: 100%;
    height: 45px;   
}

.ctn-idioma .dropdownMenu li a 
{
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: .5rem;
    color: var(--dark);
    border-radius: 0;
    margin: 0;
}

.ctn-idioma .dropdownMenu li a:hover 
{
    background: rgba(0,0,0,.3);
    transform: translate(0);
}

.ctn-idioma .dropdownMenu li a.act 
{
    background: var(--blue) ;
    color: var(--white);
}

.ctn-idioma .dropdownMenu li a img 
{
    height: 25px;
}


/*************************************************************************/
/* HERO ******************************************************************/
/*************************************************************************/
.hero 
{
    background: var(--gradient-1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100vh;
}

.hero:after 
{
    content: "";
    width: 95%;
    height: 90vh;
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/carlos%20rivera%20web.png?alt=media&token=9a8998c1-2c7d-41d5-83e8-d64c1478fca8');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--transitions);
    z-index: 1;
    opacity: .5;
}

@media screen and (min-width: 768px)
{   
    .hero:after 
    {
        width: 65%;
        opacity: .8;
    } 
}

@media screen and (min-width: 992px)
{   
    .hero:after 
    {
        width: 50%;
    } 
}

@media screen and (min-width: 1200px)
{   
    .hero:after 
    {
        width: 40%;
    } 
}

.hero .hero-title 
{
    max-width: 43.75rem;
    position: relative;
    z-index: 2;
}

.hero .hero-title h1
{
    font-size: var(--fs-1);
}

.hero .hero-title p 
{
    font-size: var(--fs-3);
    font-weight: 200;
}

.arrow-down
{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    bottom: 5rem;
    margin-left: -20px;
    font-size: 3rem;
    color: var(--white);
    animation: arrowDown 2.5s infinite;
}

/*************************************************************************/
/* TECNOLOGIAS *********************************************************/
/*************************************************************************/
.tecnologias .title-section
{

    transform: translateY(30%);
    transition: var(--transitions-animate); 
}

.tecnologias.act .title-section
{
    opacity: 1;
    transform: translateY(0%);
    transition: var(--transitions-animate); 
}

.tecnologias-ctn
{
    display: flex;
    flex-wrap: wrap;
}

.card-item
{
    width: 50%;
    padding: 1.5rem;
    border: 1px solid rgba(255,255,255,.1);
    text-align: center;
    transition: var(--transitions);
    position: relative;
}

.card-item:hover
{
    transition: var(--transitions); 
    background: rgba(41, 98, 255, .3);
}  

@media screen and (min-width: 576px) 
{
    .card-item
    {
        width: 50%;
    }
}

@media screen and (min-width: 768px) 
{
    .card-item
    {
        width: 33.33%;
        padding: 2.5rem 1.5rem;
    }
}	

@media screen and (min-width: 992px) 
{
    .card-item
    {
        width:25%;
    } 
}	

@media screen and (min-width: 1200px) 
{
    .card-item
    {
        width:20%;
    }
}			

.card-item .card-item-head 
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    margin: 0 auto 2rem auto;
}

@media screen and (min-width: 768px) 
{
    .card-item .card-item-head
    {
        height: 6rem;
        width: 6rem;
    }
}		

.card-item .card-item-head img
{
    object-fit: contain;
    height: 100%;
    max-height: 5rem;
}

.tecnologias-ctn .card-relleno .card-item-head img
{
    content: url('https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/imagen-relleno.svg?alt=media&token=6289397b-e188-46b8-9739-b09ae403fa7d');
    -webkit-animation: fadeInBack 1s ease infinite;
    animation: fadeInBack 1s ease infinite;
}

.tecnologias-ctn .card-relleno .card-item-body p
{
    background: #3e3e3e;
    padding: 1rem;
    -webkit-animation: fadeInBack 1s ease infinite;
    animation: fadeInBack 1s ease infinite;
}

/*************************************************************************/
/* TRABAJOS **************************************************************/
/*************************************************************************/
.trabajos
{
    position: relative;
}

.trabajos .title-section
{
    opacity: 0;
    transform: translateY(50%);
    transition: var(--transitions-animate); 
}

.trabajos.act .title-section
{
    opacity: 1;
    transform: translateY(0%);
    transition: var(--transitions-animate); 
}

.trabajos .card-work
{
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    border: 1px solid rgba(255,255,255,.2);
    height: 100%;
    padding: 2rem;
    transition: var(--transitions); 
    position: relative;
    box-shadow: none;
}

.trabajos .card-work:hover 
{
    transition: var(--transitions); 
    background: rgba(41, 98, 255, .3);
}

.trabajos .card-work .card-head 
{
    text-align: center;
    margin-bottom: .5rem;
}

.trabajos .card-work .card-head img 
{
    width: 19.688rem;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,.5))
}

.trabajos .card-work .card-body
{
    text-align: center;
    padding: 1.5rem 1rem 0 1rem;
}

.trabajos .card-work .card-body *
{
    color: var(--white);
}

.trabajos .card-work .card-body-title
{
    font-size: 25px;
    margin-bottom: 1rem;
}

.trabajos .card-work .card-body-main
{
    margin-top: 1rem;
}

.trabajos .card-work .btn
{
    margin: 0 auto;
}

.trabajos .card-work .btn:hover
{
    transform: scale(1);
    background-color: var(--blue);
    color: var(--white);
}

.trabajos .card-work .etiqueta
{
    margin-top: 1rem;
    text-align: center;
}

.trabajos .card-work .etiqueta 
{
    border-radius: var(--radius-50);
    padding: .2rem .7rem;
    color: var(--dark);
    font-size: .875rem;
    font-weight: 500;
    margin: .2rem .1rem;
    display: inline-block;
}

.trabajos .card-work .etiqueta.green 
{
    background-color: var(--opacity-green);
}

.trabajos .card-work .etiqueta.blue 
{
    background-color: var(--opacity-blue);
}

.trabajos .card-work .etiqueta.yellow 
{
    background-color: var(--opacity-yellow);
}

.trabajos .card-work .etiqueta.red 
{
    background-color: var(--opacity-red);
}

.trabajos .card-work .etiqueta.purple 
{
    background-color: var(--opacity-purple);
}

.trabajos .card-work .etiqueta.gray 
{
    background-color: var(--opacity-gray);
}

.trabajos .card-work .etiqueta.fucsia 
{
    background-color: var(--opacity-fucsia);
}



.trabajos .card-relleno .card-head img
{
    width: 12rem;
    content: url('https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/imagen-relleno.svg?alt=media&token=6289397b-e188-46b8-9739-b09ae403fa7d');
    -webkit-animation: fadeInBack 1s ease infinite;
    animation: fadeInBack 1s ease infinite;
}

.trabajos .card-relleno .card-body-title,
.trabajos .card-relleno .card-body-main
{
    padding: 1rem;
}

.trabajos .card-relleno .card-body-title,
.trabajos .card-relleno .card-body-main,
.trabajos .card-relleno .btn
{
    background: #3e3e3e;
    -webkit-animation: fadeInBack 1s ease infinite;
    animation: fadeInBack 1s ease infinite;
}






